<template>
  <h2>1. Descrierea jocului</h2>
  <p>
    Jocul Last 60 de la Highlight Games este o simulare software unică a rezumatelor selectate aleatoriu din meciuri de
    baschet, pentru a crea oportunități de joc.
  </p>
  <h2>2. Descrierea și durata jocului</h2>
  <p>
    NBA Last 60 Scheduled conține o serie de pagini cu o anumită durată, permițând utilizatorului final să se informeze
    cu privire la detalii importante ale jocului și oportunitățile de pe piața de pariuri. Fiecare joc din NBA Last 60
    durează aproximativ 3 minute de la preambul la rezultatul final.
  </p>
  <p>
    O selecție de rezumate din partide NBA este independentă statistic, aleatorie și imprevizibilă și generată de
    Generatorul de numere aleatorii - RNG.
  </p>
  <h2>3. Piețele de pariuri</h2>
  <h3>3.1 Câștigător la general</h3>
  <p>
    Un jucător poate paria pe câștigătorul la general al meciului atât în funcție de scorul de la început, cât și pe
    baza ultimului scor din Last 60 adunate împreună.  Scorul general nu poate fi niciodată o remiză în NBA Last 60.
  </p>
  <h3>3.2 Câștigător în Last 60</h3>
  <p>Pariu pe scorul echipei câștigătoare din Last 60. Acest scor din Last 60 nu poate fi niciodată o remiză. </p>
  <h3>3.3 Ambele echipe vor înscrie peste 2,5 puncte</h3>
  <p>
    Jucătorul poate paria atât pe echipa gazdă, cât și pe echipa oaspete că va înscrie cel puțin 2,5 puncte fiecare
    într-un singur meci din NBA Last 60.
  </p>
  <h3>3.4 Ambele echipe vor înscrie peste 3,5 puncte</h3>
  <p>
    Atât echipa gazdă, cât și pe echipa oaspete trebuie să înscrie cel puțin 3,5 puncte fiecare într-un singur meci din
    NBA Last 60.
  </p>
  <h3>3.5 Total aruncări de 3 puncte în Last 60</h3>
  <p>
    Pronostic al numărului total de aruncări de 3 puncte din meci, acest lucru putând fi combinat, astfel încât, dacă
    fie echipa gazdă sau echipa oaspete înscrie o aruncare de 3 puncte, aceasta va fi adunată la numărul total de
    aruncări de 3 puncte. Numărul maxim de aruncări de 3 puncte dintr-un meci poate fi de 6.
  </p>
  <h3>3.6 Prima echipă care înscrie în Last 60</h3>
  <p>Prima echipă gazdă sau oaspete care înscrie un punct în NBA Last 60.</p>
  <h3>3.7 Diferență de puncte în Last 60</h3>
  <p>
    Estimează marja de victorie a echipei câștigătoare în jocul Last 60, exemple de marjă de victorie: 1-3, 4-6, 7-9.
    10-12 sau mai mult de 12 puncte.
  </p>
  <h3>3.8 Prima echipă care înscrie 4 puncte în Last 60</h3>
  <p>
    Prima echipa, gazdă sau oaspete, care înscrie 4 puncte în jocul Last 60. Rezultatul „Niciuna dintre echipe” nu este
    o selecție validă pentru această piață. 
  </p>
  <h3>3.9 Echipa 1 Peste/Sub 4,5 puncte în Last 60</h3>
  <p>Punctele înscrise de Echipa 1 (Gazdă) în Last 60 vor fi peste sau sub 4,5.</p>
  <h3>3.10 Echipa 2 Peste/Sub 4,5 puncte în Last 60</h3>
  <p>Punctele înscrise de Echipa 2 (Oaspete) în Last 60 vor fi peste sau sub 4,5.</p>
  <h3>3.11 Echipa 1 Peste/Sub 5,5 puncte în Last 60</h3>
  <p>Punctele înscrise de Echipa 1 (Gazdă) în Last 60 vor fi peste sau sub 5,5.</p>
  <h3>3.12 Echipa 2 Peste/Sub 5,5 puncte în Last 60</h3>
  <p>Punctele înscrise de Echipa 2 (Oaspete) în Last 60 vor fi peste sau sub 5,5.</p>
  <h3>3.13 Câștigător și peste/sub 11,5 puncte în Last 60</h3>
  <p>
    Pronostic al câștigătorului meciului din Last 60, precum și dacă totalul de puncte înscrise în Last 60 va fi de
    peste sau sub 11,5 puncte ca un pariu combinat. (Această piață este fixată la 11,5 și nu are valori variabile ca în
    cazul unora dintre piețele de mai sus.) 
  </p>
  <h3>3.14 Câștigător și marja de victorie în Last 60</h3>
  <p>
    Pronostic al câștigătorului meciului din Last 60, precum și marja de victorie, adică diferența de puncte dintre
    echipe.
  </p>
</template>
